import { graphql, useStaticQuery } from 'gatsby';
import MobileDevelopmentIcon from 'svgs/single-case-study/mobile-development.svg';
import ReactJsIcon from 'svgs/single-case-study/react-js.svg';
import UxUiDesign from 'svgs/single-case-study/ux-ui-design.svg';

import { contentResolver } from '../utilities';

const faqPath = 'faq-section.content';
const FAQ_ITEMS = [
    {
        question: `${faqPath}.q1`,
        answer: `${faqPath}.a1`,
    },
    {
        question: `${faqPath}.q2`,
        answer: `${faqPath}.a2`,
    },
    {
        question: `${faqPath}.q3`,
        answer: `${faqPath}.a3`,
    },
    {
        question: `${faqPath}.q4`,
        answer: `${faqPath}.a4`,
    },
    {
        question: `${faqPath}.q5`,
        answer: `${faqPath}.a5`,
    },
];

const content = contentResolver({
    project: 'moneyTransferApplication',
    brandColor: '--money-transfer-application-case-study-purple',
    scopeOfWork: [
        {
            title: 'scopeOfWork.mobileDevelopment',
            Icon: MobileDevelopmentIcon,
        },
        {
            title: 'scopeOfWork.reactNative',
            Icon: ReactJsIcon,
        },
        {
            title: 'scopeOfWork.uxUiDesign',
            Icon: UxUiDesign,
        },
    ],
    projectInfo: {
        count: 2,
        dataIndexes: [0, 2],
    },
    paragraphsCounters: {
        header: 1,
        about: 3,
        challenge: 3,
        mainGoals: 1,
        solutions: 1,
        summary: 1,
    },
    caseStudies: ['cryptocurrencyExchangePlatform', 'nomisSolutions', 'churn'],
    caseStudiesSubtitle: 'case-studies.subtitle',
    faqTitle: 'faq-section.title',
    faqData: FAQ_ITEMS,
});

content.headerImageAlt = 'money transfer software';
content.about.alt = 'remittance software';
content.challenge.alt = 'remittance software development';
content.mainGoals.alt = 'remittance software app development';
content.solutions.alt = 'money transfer software development';
content.summary.alt = 'money transfer software app development';

export const useMoneyTransferApp = () => {
    const images = useStaticQuery(
        graphql`
            query {
                headerImage: file(
                    relativePath: {
                        eq: "single-case-study/money-transfer-application/mobile.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                aboutImage: file(
                    relativePath: {
                        eq: "single-case-study/money-transfer-application/about.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                challengeImage: file(
                    relativePath: {
                        eq: "single-case-study/money-transfer-application/challenge.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                mainGoalsImage: file(
                    relativePath: {
                        eq: "single-case-study/money-transfer-application/main-goals.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                solutionsImage: file(
                    relativePath: {
                        eq: "single-case-study/money-transfer-application/solutions.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                summaryImage: file(
                    relativePath: {
                        eq: "single-case-study/money-transfer-application/summary.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
            }
        `,
    );

    return {
        data: { ...images, ...content },
    };
};
